<template>
  <div v-if="modules && !!modules['invoices']" class="row">
    <div class="col">
      <b-card title="Balances">
        <blueprint-data api-route-path="balances" :fields="fields" :where="where" no-new no-actions>
          <template v-slot:search>
            <b-form-group label-cols-sm="4" label-cols-lg="4" label="Booking no.">
              <b-form-input v-model="bokingNo" type="text" placeholder="Search by booking no" @input="buildWhere" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="4" label="Booking firstname">
              <b-form-input v-model="firstname" type="text" placeholder="Search by booking firstname" @input="buildWhere" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="4" label="Booking lastname">
              <b-form-input v-model="lastname" type="text" placeholder="Search by booking lastname" @input="buildWhere" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Company" label-for="company">
              <blueprint-search-input
                id="company"
                v-model="companyId"
                placeholder="Type to search"
                api-route-path="companies"
                @input="buildWhere"
              />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="4" label="Invoiced">
              <b-form-input v-model="invoicedMin" type="text" style="width: calc(50% - 1rem);float:left" placeholder="Min" @input="buildWhere" />&nbsp;
              <b-form-input v-model="invoicedMax" type="text" style="width: calc(50% - 1rem);float:right" placeholder="Max" @input="buildWhere" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="4" label="Payments">
              <b-form-input v-model="paymentsMin" type="text" style="width: calc(50% - 1rem);float:left" placeholder="Min" @input="buildWhere" />&nbsp;
              <b-form-input v-model="paymentsMax" type="text" style="width: calc(50% - 1rem);float:right" placeholder="Max" @input="buildWhere" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="4" label="Balance">
              <b-form-input v-model="balanceMin" type="text" style="width: calc(50% - 1rem);float:left" placeholder="Min" @input="buildWhere" />&nbsp;
              <b-form-input v-model="balanceMax" type="text" style="width: calc(50% - 1rem);float:right" placeholder="Max" @input="buildWhere" />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="4" label="Status" label-for="status">
              <b-form-select id="status" v-model="status" @input="buildWhere">
                <option :value="null">
                  -
                </option>>
                <option value="CONFIRMED">
                  Confirmed
                </option>>
                <option value="ACTIVATED">
                  Active
                </option>>
                <option value="CLOSED">
                  Closed
                </option>
              </b-form-select>
            </b-form-group>
          </template>
          <template v-slot:booking.number="{ item }">
            <router-link :to="{ name: 'bookings.booking.edit', params: { id: item.booking.id } }">
              {{ item.booking.number }}
              <b-badge v-if="item.booking.status==='NEW'" variant="info">
                New
              </b-badge>
              <b-badge v-if="item.booking.status==='CONFIRMED'" variant="warning">
                Confirmed
              </b-badge>
              <b-badge v-if="item.booking.status==='CANCELED'" variant="danger">
                Canceled
              </b-badge>
              <b-badge v-if="item.booking.status==='CLOSED'" variant="dark">
                Closed
              </b-badge>
              <b-badge v-if="item.booking.status==='ACTIVATED'" variant="success">
                Activated
              </b-badge>
            </router-link>
          </template>
          <template v-slot:locked="{ item }">
            <b-badge v-if="item.locked===false" variant="danger">
              Not locked
            </b-badge>
            <b-badge v-if="item.locked===true" variant="success">
              Locked
            </b-badge>
          </template>
          <template v-slot:bookingId="{ item }">
            {{ item.booking.firstname }}
            {{ item.booking.lastname }}
            <span v-if="item.booking.company">({{ item.booking.company.name }})</span>
          </template>
          <template v-slot:balance="{ item }">
            <span :class="{ 'text-danger': item.balance < 0 }">{{ item.balance }}</span>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
import utils from '../../../libs/utils.vue';

export default {
  data () {
    return {
      status: null,
      bokingNo: null,
      firstname: null,
      lastname: null,
      companyId: null,
      invoicedMin: null,
      invoicedMax: null,
      paymentsMin: null,
      paymentsMax: null,
      balanceMin: null,
      balanceMax: null,
      where: {},
      month: 1,
      fields: [
        {
          key: 'booking.number',
          label: 'Booking',
          sortable: false,
          slot: true
        },
        {
          key: 'bookingId',
          label: 'Booking Owner',
          sortable: true,
          slot: true
        },
        {
          key: 'invoiced',
          label: 'Invoiced',
          sortable: true,
          slot: false
        },
        {
          key: 'payments',
          label: 'Payments',
          sortable: true,
          slot: false
        },
        {
          key: 'balance',
          label: 'Balance',
          sortable: true,
          slot: true
        },
      ],
    };
  },
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
  methods: {
    buildWhere () {
      this.where = {
        ...utils.parseWhereItem('booking.status', this.status),
        ...utils.parseWhereItem('booking.number', this.bokingNo? this.bokingNo : null),
        ...utils.parseWhereItem('booking.firstname', this.firstname? this.firstname : null),
        ...utils.parseWhereItem('booking.lastname', this.lastname? this.lastname : null),
        ...utils.parseWhereItem('booking.companyId', this.companyId? this.companyId : null),
        ...utils.parseMinMaxItem('invoiced', this.invoicedMin, this.invoicedMax),
        ...utils.parseMinMaxItem('payments', this.paymentsMin, this.paymentsMax),
        ...utils.parseMinMaxItem('balance', this.balanceMin, this.balanceMax),
      };
    },
  },
};
</script>
